#private-main {
  margin-top: 115px;
  padding: 0 0 35px;
}

.politica-de-privacidade {
    padding: 50px 0;
    background-color: #f1f1f1;
    margin-right: -15px;
    margin-left: -15px;
    text-align: justify;
  }
  
  .politica-de-privacidade:before, .politica-de-privacidade:after {
    display: table;
    content: " ";
  }
  
  .politica-de-privacidade:after {
    clear: both;
  }
  
  .politica-de-privacidade .card {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    background-color: #fff;
    padding: 50px 40px;
  }
  
  @media (min-width: 768px) {
    .politica-de-privacidade .card {
      float: left;
      width: 83.33333%;
    }
  }
  
  @media (min-width: 768px) {
    .politica-de-privacidade .card {
      margin-left: 8.33333%;
    }
  }
  
  .politica-de-privacidade .card .headline h2 {
    font-size: 25px;
  }
  
  .politica-de-privacidade .card h2 {
    font-size: 18px;
  }
  
  .politica-de-privacidade .card p {
    font-size: 15px;
  }
  
