.label-360 a {
    display: flex;
    position: absolute !important;
    z-index: 200;
    top: 10px;
    background-color: var(--color-white);
    color: var(--color-secondary);
    border-radius: 5px;
    padding: 5px;
    height: 30px;
    font-size: 14px !important;
    align-items: center;
    margin: 8px;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
}
.label-360 img {
    margin-right: 5px !important;
    max-width: 25px !important;


}
.label-360 a:hover {    
    background-color:var(--color-white);
    color: var(--color-primary) !important;    
}

.label-360 a i {
    font-size: 20px;
}