.headline {
    text-align: center;
}

.headline h2 {
    color: #c40000;
    margin: 0;
    font-family: 'Merriweather Sans', sans-serif;
    font-weight: 500;
    padding: 0 15px;
    font-size: 25px;
}

.headline h2:after {
    content: "";
    display: table;
    margin: 10px auto;
    width: 4%;
    height: 4px;
    background-color: #000000;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

