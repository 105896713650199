/* GLOBALS */
select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    background: url('../../assets/images/icons/down-arrow.svg') no-repeat right !important;
    background-size: 16px !important;
    background-color: var(--color-white) !important;
}

.back-to-top {
    z-index: 1000;
}

.rc-loading::after {
    background-color: var(--color-grey-lighter) !important;
    border-radius: 10px;
}

.btn-primary {
    color: var(--color-white) !important;
    background: var(--color-primary);
}

.btn-primary:hover {
    color: var(--color-white);
    background: var(--color-primary);
}

@media (min-width: 1600px) {
    .container {
        max-width: 1300px;
    }
}

/* SCROLLBAR */
::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
    background: #dad7d7;
}

/* REACTSTRAP */
.breadcrumb {
    background-color: transparent !important;
    padding: 0;
}

.breadcrumb-item {
    margin: 0;
    font-size: 13px;
    font-weight: 600;   
}

.breadcrumb-item.active {
    font-weight: inherit;   
}

.breadcrumb-item::before {
    content: '';
    margin-right: 0;
}

.form-control:focus {
    box-shadow: none;
}

/* MATERIAL UI */
.MuiSwitch-colorSecondary-7.MuiSwitch-checked-5 {
    color: var(--color-primary);
}

.MuiSwitch-colorSecondary.Mui-checked  {
    color: var(--color-primary) !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: var(--color-black) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--color-primary) !important;
}

/* OWL CAROUSEL */
.owl-nav {
    display: flex;
    justify-content: space-between;
    margin-top: 0 !important;
}

.owl-nav .owl-prev, .owl-nav .owl-next {
    position: absolute;
    top: 45%;
    width: 40px;
    height: 40px;
    text-align: center;    
    background-color: var(--color-primary) !important;
    border-radius: 5px;
}

.owl-nav .owl-prev span, .owl-nav .owl-next span {
    font-size: 20px;
    color: var(--color-white);
    transition: .5s;
}

.owl-nav .owl-prev:hover span, 
.owl-nav .owl-next:hover span {
    color: var(--color-white);
}

.owl-nav .owl-prev:focus, .owl-nav .owl-next:focus {
    outline: none;
}

.owl-nav .owl-prev {
    left: -10px;
    z-index: 1200;
}

.owl-nav .owl-next {
    right: -10px;
}

.owl-dots {
    display: none;
}

/* SELECT2 */
.select2-container {
    width: 100% !important;
}

.select2-container .select2-selection--single {
    height: 48px;
    padding: 0 15px;
    font-size: 1rem;
}

.form-box .busca
.select2-container--default 
.select2-selection--single 
.select2-selection__rendered {
    line-height: 45px;
    padding: 0;   
}

.select2-container--default 
.select2-selection--single {
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.select2-dropdown {
    z-index: 10000;
    border: 1px solid #ced4da;
}

.select2-container--default 
.select2-search--dropdown 
.select2-search__field {
    border: 1px solid #ced4da;
}

.select2-container--default 
.select2-search--dropdown 
.select2-search__field:focus {    
    border: 1px solid var(--color-primary);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {   
    background: url('../../assets/images/icons/down-arrow.svg') no-repeat right;
    background-size: 16px;
    background-position-y: 16px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
}

/* SWEET ALERT 2 */
.swal2-container {
    z-index: 11060 !important;
}

.swal2-close:focus {
    box-shadow: none !important;
}

.MuiAutocomplete-groupLabel {
    font-weight: 700 !important;
}